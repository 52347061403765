import React from "react"
import AnimatedLink from "../animated-link"
import * as Styled from "./styled"

const Navigation = () => {
  return (
    <Styled.Navigation>
      <AnimatedLink
        isInternalLink={ true }
        target="/"
        linkText="SFB Fonts"
      />
      <AnimatedLink
        isInternalLink={ false }
        target="https://www.studiofabiobiesel.com/"
        linkText="Studio Page →"
      />
      <AnimatedLink
        isInternalLink={ true }
        target="/about"
        linkText="About/FAQ"
      />
    </Styled.Navigation>
  )
}

export default Navigation