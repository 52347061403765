import React from "react"
import SEO from "../seo"
import { GlobalReset, GlobalStyle } from "../styles/global"
import Navigation from "../components/navigation"

const LayoutDefault = props => {
  return (
    <>
      <SEO />
      <GlobalReset />
      <GlobalStyle />
      <Navigation />
      <main>
        { props.children }
      </main>
    </>
  )
}

export default LayoutDefault