import theme from "styled-theming"

export const bodyBackgroundColor = theme("mode", {
  light: "#fff",
  dark: "#000"
})

export const mainColor = theme("mode", {
  light: "#000",
  dark: "#fff"
})

export const margins = {
  page: "20px",
  minimal: "6px",
  base: "10px",
  medium: "20px",
  large: "40px"
}

export const mobileMargins = {
  page: "12px"
}

export const elementHeights = {
  navDesktop: 66,
  navMobile: 60
}

export const zIndexes = {
  navigation: 99999,
  sliderNav: 999,
  sliderFooter: 9999,
}

export const fontSizes = {
  xxsmall: "12px",
  xsmall: "14px",
  small: "16px",
  base: "20px",
  medium: "34px",
  large: "86px"
}

export const lineHeights = {
  base: "1.26",
  mobileBase: "1.3",
  large: "1.12",
  mobileLarge: "1.14"
}