import styled from "styled-components"
import * as themeSettings from "../../styles/theme"

export const Navigation = styled.nav`
  box-sizing: border-box;
  position: fixed;
  top: 0;
  width: 100vw;
  max-width: 100%;
  height: ${themeSettings.elementHeights.navDesktop}px;
  padding: 0 ${themeSettings.margins.page};
  
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: ${themeSettings.bodyBackgroundColor};

  z-index: ${themeSettings.zIndexes.navigation};

  & > span {
    /* Center Studio Page Link horizontally */
    &:nth-child(2) {
      position: absolute;
      left: calc(50% - 69px);
      @media screen and (max-width: 414px) {
        position: initial;
      }
    }
  }

  @media screen and (max-width: 414px) {
    padding: 0 ${themeSettings.mobileMargins.page};
    height: ${themeSettings.elementHeights.navMobile}px;
  }
  
`